<template>
	<div id="app">
		<v-app id="inspire">
			<v-row v-for="(log, index) in logs" :key="log?.id">
				<v-col md="1"></v-col>
				<v-col md="11">
					<v-timeline dense>
						<v-timeline-item class="align-center" dot small color="green">
							<span class="text-h6 text-capitalize" v-if="index == 'today' || index == 'yesterday'">{{
								index
							}}</span>
							<span class="text-h6 text-capitalize" v-else>{{ formatDate(index) }}</span>
						</v-timeline-item>
						<template v-for="item in log">
							<v-timeline-item class="" color="grey" icon-color="grey lighten-2" small :key="item?.id">
								<span slot="opposite"
									><v-icon class="mdi-16px mr-1 primary--text">mdi-clock-outline</v-icon>
									<span>{{ formatTime(item?.added_at) }}</span></span
								>
								<v-card class="elevation-2" max-width="400">
									<v-card-text class="pa-2">
										<div class="d-flex mb-2 fw-500">
											<div class="">
												<span class="fw-600 text-capitalize">{{ heading }}</span>
												Activity
											</div>
											<v-spacer></v-spacer>
											<div class="ip-address">
												<v-tooltip top content-class="custom-top-tooltip">
													<template v-slot:activator="{ on, attrs }">
														<v-icon v-on="on" v-bind="attrs" small>mdi-information-outline</v-icon>
													</template>
													<span>Ip Address: {{ item?.ip }}</span>
												</v-tooltip>
											</div>
										</div>
										<div class="d-flex mb-2 fw-500">
											<span
												class="mr-1 text-capitalize"
												v-html="item?.description"
												style="word-break: break-all"
											></span>
											by
											<span class="ml-1 text-capitalize">
												<v-chip x-small color="blue darken-2" dark class="px-1">
													<v-avatar left>
														<v-img
															src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/files/image/png/b2f1fe64-0645-43d9-81eb-88e61638588c.png"
														></v-img>
													</v-avatar>
													<span>{{ item?.added_by?.display_name }}</span></v-chip
												>
											</span>
										</div>
									</v-card-text>
								</v-card>
							</v-timeline-item>
						</template>
						<!-- <v-timeline-item class="" color="grey" icon-color="grey lighten-2" small>
							<span slot="opposite"
								><v-icon class="mdi-16px mr-1 primary--text">mdi-clock-outline</v-icon>
								<span>07:02 PM</span></span
							>
							<v-card class="elevation-2" width="400">
								<v-card-text>
									<div class="d-flex mb-2 fw-500">
										<div class="">
											<span class="fw-600">Opportunity</span>
											Activity
										</div>
										<v-spacer></v-spacer>
										<div class="ip-address">
											<v-tooltip top content-class="custom-top-tooltip">
												<template v-slot:activator="{ on, attrs }">
													<v-icon v-on="on" v-bind="attrs" small>mdi-information-outline</v-icon>
												</template>
												<span>Ip Address: 103.107.92.123</span>
											</v-tooltip>
										</div>
									</div>
									<div class="d-flex mb-2 fw-500">
										<span class="mr-1">Opportunity #BT-0000498 Updated </span>
										by
										<span class="ml-1 text-capitalize">
											<v-chip small color="blue darken-2" dark class="px-1">
												<v-avatar left>
													<v-img
														src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/files/image/png/b2f1fe64-0645-43d9-81eb-88e61638588c.png"
													></v-img>
												</v-avatar>
												<span class="fw-500">Admin</span></v-chip
											>
										</span>
									</div>
								</v-card-text>
							</v-card>
						</v-timeline-item>

						<v-timeline-item class="align-center" small dot color="green">
							<span class="text-h6">YESTERDAY</span>
						</v-timeline-item>
						<v-timeline-item color="grey" small>
							<span slot="opposite"
								><v-icon class="mdi-16px mr-1 primary--text">mdi-clock-outline</v-icon>
								<span>07:02 PM</span></span
							>
							<v-card class="elevation-2" width="400">
								<v-card-text>
									<div class="d-flex mb-2 fw-500">
										<div class="">
											<span class="fw-600">Opportunity</span>
											Activity
										</div>
										<v-spacer></v-spacer>
										<div class="ip-address">
											<v-tooltip top content-class="custom-top-tooltip">
												<template v-slot:activator="{ on, attrs }">
													<v-icon v-on="on" v-bind="attrs" small>mdi-information-outline</v-icon>
												</template>
												<span>Ip Address: 103.107.92.123</span>
											</v-tooltip>
										</div>
									</div>
									<div class="d-flex mb-2 fw-500">
										<span class="mr-1">Opportunity #BT-0000498 Updated </span>
										by
										<span class="ml-1 text-capitalize">
											<v-chip small color="blue darken-2" dark class="px-1">
												<v-avatar left>
													<v-img
														src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/files/image/png/b2f1fe64-0645-43d9-81eb-88e61638588c.png"
													></v-img>
												</v-avatar>
												<span class="fw-500">Admin</span></v-chip
											>
										</span>
									</div>
								</v-card-text>
							</v-card>
						</v-timeline-item> -->
					</v-timeline>
				</v-col>
			</v-row>
		</v-app>
	</div>
</template>

<script>
import main from "@/core/mixins/main.mixin";

export default {
	name: "Activity",
	mixins: [main],

	props: {
		logs: {
			type: [Object, Array],
		},
		heading: {
			type: String,
		},
	},

	data() {
		return {
			isEditing: false,
		};
	},
};
</script>
<style scoped>
#inspire .v-timeline--dense .v-timeline-item__opposite {
	display: block !important;
}
</style>
